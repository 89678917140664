export const API_ROUTES = {
  AUTH: {
    LOGIN: "/auth/admin/login",
  },
  CMS: {
    LIST: "/admin/cms",
    UPDATE: "/admin/cms",
  },
  USERS: {
    LIST: "/admin/users",
    EVENT_LIST: '/event/user/list'
  },
  PAYMENT: {
    LIST: "/admin/order/list",
    PARTNERS_LIST: "/order/partner-websites",
  },
  PROFILE: {
    SPECIFIC: "/auth/profile",
    UPDATE: "/auth/profile",
  },
  PROMOS: {
    LIST: "/admin/promos",
    UPDATE: "/admin/promos",
  },
  COUPONS: {
    LIST: "/admin/coupons",
    UPDATE: "/admin/coupons",
  },
  COUNTRIES: {
    LIST: "/regions/countries",
    UPDATE_POPULAR_COUNTRIES: "/regions/countries",
    POPULAR_COUNTRIES_UPDATE: "/regions/popularCountries"
  },
  REGIONS: {
    LIST: "/regions",
  },
  LOYALITY_POINTS: {
    LIST: "/admin/loyalityPoints",
    UPDATE: "/admin/loyalityPoints",
    USER: "/admin/loyalityPoints/user"
  },
  BLOGS: {
    CREATE: "/blogs",
    UPDATE: "/blogs",
    LIST: "/admin/blogs",
    SPECIFIC: "/blogs",
    UPDATE_BLOG: "/admin/blogs",
  },
  BLOGS_COMMENTS: {
    UPDATE: "/blogs/comment",
    LIST: "/blogs/comments",
  },
  UPLOAD_FILE: "/common/upload",
  FAQ: {
    LIST: "/faq"
  },
  TROUBLESHOOT: {
    LIST: "/troubleShoot"
  },
  HOME_TABS: {
    LIST: "/home/tabs"
  },
  DOCUMENTS: {
    VIEW_DOCUMENTS: "/order/details"
  },
  PLANS: {
    LIST: "/admin/bundle",
    UPDATE: "/admin/bundle",
    DETAILS: "/admin/bundle/country",
  },
  NOTIFICATIONS: {
    LIST: "/notifications",
  },
  ANALYTICS: "/analytics",
  REVENUE_GRAPH: "/analytics/revenue/graph",
  UPLOAD_BUNDLE: "bundle/bulkUpload",
};