import React, { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import styles from "../styles/login.module.css";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../lib/hooks/useAuth";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import { login } from "../api";
import { CONSTANTS } from "../../../lib/constants/messages";
import { LoginAdminSuccessResponse } from "../../../interfaces";
import loginSchema from "../validations";
import { IMAGES } from "../../../assets/images";

const Login = () => {
  const navigate = useNavigate();
  const { loginUser, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(APP_ROUTES.USERS);
    }
  }, [isAuthenticated]);

  const loginFormik = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: (values, { setSubmitting }) => {
      const payload = {
        email: values.email,
        password: values.password,
      };

      toast.promise(login(payload), {
        pending: {
          render() {
            return CONSTANTS.AUTH.LOGGING;
          },
        },
        success: {
          render({ data }) {
            let response = data as LoginAdminSuccessResponse;

            setSubmitting(false);
            loginUser(response.data.accessToken);
            window.localStorage.setItem("admin_roles", response.data.role);
            loginFormik.resetForm();
            if (
              LOCAL_CONSTANTS.ROLES.NIYO_USER == response.data.role ||
              LOCAL_CONSTANTS.ROLES.VFS_USER == response.data.role ||
              LOCAL_CONSTANTS.ROLES.TRAVELTHRU_USER == response.data.role ||
              LOCAL_CONSTANTS.ROLES.GLOBALTIX == response.data.role ||
              LOCAL_CONSTANTS.ROLES.TRAVELTHRU_USER == response.data.role ||
              LOCAL_CONSTANTS.ROLES.VFS_PHILIP_USER == response.data.role
            ) {
              navigate(APP_ROUTES.PARTNERS);
            } else {
              navigate(APP_ROUTES.USERS);
            }
            return CONSTANTS.AUTH.LOGIN;
          },
        },
        error: {
          render() {
            setSubmitting(false);

            return CONSTANTS.AUTH.NOTLOGIN;
          },
        },
      });
    },
    validationSchema: loginSchema,
  });

  return (
    <div className={styles.loginPage}>
      <div className="row m-0">
        <div className="col-md-6 p-0">
          <div className={styles.loginLeft}>
            <img height={20} width={100} src={IMAGES.Logo} alt="logo" />
            <h3>International eSIMs & Data cards</h3>
            <p>
              Enter your registered email and password to manage your Dashboard,
              track your Users, and enjoy benefits.
            </p>
          </div>
        </div>

        <div className="col-md-6 p-0">
          <div className={styles.loginRight}>
            <h4>
              Sign in to
              <img height={30} width={200} src={IMAGES.WhiteLogo} alt="logo" />
            </h4>
            <form onSubmit={loginFormik.handleSubmit}>
              <div className={styles["form-group"]}>
                <label className="d-flex align-items-center">
                  Email
                  {loginFormik.touched.email && loginFormik.errors.email ? (
                    <p className={styles.error}>{loginFormik.errors.email}</p>
                  ) : null}
                </label>
                <input
                  name="email"
                  onChange={loginFormik.handleChange}
                  type="text"
                  placeholder="Input your email here"
                />
              </div>
              <div className={styles["form-group"]}>
                <label className="d-flex align-items-center">
                  Password
                  {loginFormik.touched.password &&
                  loginFormik.errors.password ? (
                    <p className={styles.error}>
                      {loginFormik.errors.password}
                    </p>
                  ) : null}
                </label>
                <input
                  onChange={loginFormik.handleChange}
                  name="password"
                  type="password"
                  placeholder="input your password in here"
                />
              </div>
              {/* <div className={styles["form-group"]}>
								<div className={styles.forgot}>
									<div className="form-check form-switch">
										<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
										<label className={styles.remeber}>Remember me</label>
									</div>
									<a href="#">Forgot password?</a>
								</div>
							</div> */}
              <div className={styles["form-group"]}>
                <input type="submit" value="Sign in" />
              </div>

              {/* <div className={styles.login}>
								<span>Or continue with</span>
								<hr />
							</div> */}
              {/* <div className={styles.social}>
								<ul>
									<li>
										<a href="#">
											<Image width={20} height={20} src={IMAGES.GOOGLE_ICON} alt="google" />
											Google
										</a>
									</li>
									<li>
										<a href="#">
											<Image width={20} height={20} src={IMAGES.FACEBOOK_ICON} alt="facebook" />
											Facebook
										</a>
									</li>
									<li>
										<a href="#">
											<Image width={20} height={20} src={IMAGES.APPLE_ICON} alt="apple" />
											Apple
										</a>
									</li>
								</ul>
							</div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
